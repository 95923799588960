<template>
    <v-app>
        <app-autenticacao />
        <v-main>
            <router-view />
        </v-main>
        <Menu :possui-tema="true" titulo="Serviços"/>
    </v-app>
</template>

<script>
import Menu from "@ufn-ti/componente-vue-front/src/views/Menu.vue"
import AppAutenticacao from "@ufn-ti/componente-vue-front/src/components/comuns/AppAutenticacao.vue"

export default {
  name: "App",

  components: {
    // eslint-disable-next-line
    Menu,
    AppAutenticacao,
  },
  mounted () {
    this.$watch(
      () => this.$vuetify.theme.dark,
      () => [this.$route.query.user, this.$route.query.token, this.$route.query.expiracao, this.$route.query.renovadorToken],
            () => {
                if (this.$route.query.user && this.$route.query.token && this.$route.query.expiracao && this.$route.query.renovadorToken) {
                    this.guardarToken()
                }
            }
    )
  },
}
</script>

<style>
header {
  left: 0 !important;
}